import React from "react";
import { useContext, useEffect } from "react";
import { wsInit } from "common/ws/ws_notify";
import { messageReceiveHandle } from "common/ws/ws_message";
import { WsNotifyContext } from "common/ws/WsNotify";
import WsnConnectionMsg from "./WsnConnectionMsg";
import WsnTestCont from "./WsnTestCont";

const NotifyTest = () => {
  const wsNotifyContextValue = useContext(WsNotifyContext);
  useEffect(() => {
    wsInit(messageReceiveHandle, {
      notifyTime: wsNotifyContextValue.notifyTime,
      setNotifyTime: wsNotifyContextValue.setNotifyTime,
      setConnectionInfo: wsNotifyContextValue.setConnectionInfo,
    });
  }, []);

  return (
    <>
      <div>Notify test</div>
      <div>
        wsNotifyContextValue:
        {wsNotifyContextValue.notifyTime
          ? wsNotifyContextValue.notifyTime.toLocaleString()
          : ""}
      </div>
      <WsnTestCont />
      <WsnConnectionMsg />
    </>
  );
};

export default NotifyTest;
