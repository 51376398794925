import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NoticrudStart from "components/pages/NoticrudStart";
import LoginPage from "components/pages/LoginPage";
import LogoutPage from "components/pages/LogoutPage";
import MainView from "./MainView";
import LoginView from "./LoginView";
import UserGuide from "components/pages/UserGuide";
import { ConfirmDialogProvider } from "common/modal/ConfirmDialog";
import Noticrud from "components/pages/Noticrud";
import HtmlTextList from "components/pages/html_text/HtmlTextList";
import HtmlTextNew from "components/pages/html_text/HtmlTextNew";
import HtmlText from "components/pages/html_text/HtmlText";
import WsNotify from "common/ws/WsNotify";
import NotifyTest from "components/pages/NotifyTest";

export default function NoticrudMainContent() {
  return (
    <ConfirmDialogProvider>
      <Router>
        <Routes>
          <Route
            element={
              <WsNotify>
                <MainView />
              </WsNotify>
            }
          >
            <Route path="/" element={<NoticrudStart />} />
            <Route path="/html-text">
              <Route path="list" element={<HtmlTextList />} />
              <Route path="new" element={<HtmlTextNew />} />
              <Route path=":htmlTextId" element={<HtmlText />} />
            </Route>
            <Route path="/noticrud" element={<Noticrud />} />
            <Route path="/uputstvo" element={<UserGuide />} />
            <Route path="/notify-test" element={<NotifyTest />} />
          </Route>
          <Route element={<LoginView />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
          </Route>
        </Routes>
      </Router>
    </ConfirmDialogProvider>
  );
}
