import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useHtmlTextGet } from "hooks/noticrud/html_text/useHtmlTextGet";
import HtmlTextEdit from "./HtmlTextEdit";
import HtmlTextRead from "./HtmlTextRead";

const HtmlTextComponent = () => {
  const { htmlTextId } = useParams();
  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState<string>("");
  const htmlText = useHtmlTextGet(htmlTextId as string);
  useEffect(() => {
    const paramsMode = searchParams.get("mode") ?? "";
    if (paramsMode !== mode) {
      setMode(paramsMode);
    }
  }, [searchParams, htmlText, mode]);

  if (!htmlText) {
    return null;
  }

  return (
    <>
      {mode !== "edit" && <HtmlTextRead htmlText={htmlText} />}
      {mode === "edit" && <HtmlTextEdit htmlText={htmlText} />}
    </>
  );
};

export default HtmlTextComponent;
