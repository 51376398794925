import { WS_MESSAGE_TYPES } from "constant/web_socket_notify";

export const messageReceiveHandle = (
  data: any,
  messageHandleAddParams: any
) => {
  console.log("messageReceiveHandle, received: %s", data);
  const msgOb = JSON.parse(data);
  const msgType = msgOb.msgType;
  const payload = msgOb.payload;
  if (msgType === WS_MESSAGE_TYPES.RECEIVE.NOTIFY_TIME) {
    let msgNotifyTime = payload;
    msgNotifyTime = new Date(msgNotifyTime);
    messageHandleAddParams.setNotifyTime(msgNotifyTime);
  }
};
