import React, { createContext, useState } from "react";

type WsConnectionInfo = {
  ok: boolean;
  message: string;
};

type WsNotifyValue = {
  notifyTime: Date | null;
  setNotifyTime: Function;
  connectionInfo: WsConnectionInfo;
  setConnectionInfo: Function;
};

export const WsNotifyContext = createContext<WsNotifyValue>({
  notifyTime: null,
  setNotifyTime: () => {
    console.log("setTestValue init");
  },
  connectionInfo: {
    ok: false,
    message: "",
  },
  setConnectionInfo: () => {
    console.log("setConnectionInfo init");
  },
});

type ProviderProps = { children: React.ReactNode };

export const WsNotify = ({ children }: ProviderProps) => {
  const [notifyTime, setNotifyTime] = useState<Date | null>(null);
  const [connectionInfo, setConnectionInfo] = useState<WsConnectionInfo>({
    ok: false,
    message: "Not connected to web socket for update notification",
  });

  const setNotifyTimeContextValue = (value: Date | null) => {
    setNotifyTime(value);
  };

  const setConnectionInfoContextValue = (value: WsConnectionInfo) => {
    console.log("setConnectionInfoContextValue", value);

    setConnectionInfo(value);
  };

  return (
    <WsNotifyContext.Provider
      value={{
        notifyTime,
        setNotifyTime: setNotifyTimeContextValue,
        connectionInfo,
        setConnectionInfo: setConnectionInfoContextValue,
      }}
    >
      {children}
    </WsNotifyContext.Provider>
  );
};
export default WsNotify;
