import { useContext, useEffect } from "react";
import { WsNotifyContext } from "common/ws/WsNotify";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { useTestContentGet } from "hooks/noticrud/test_content/useTestContentGet";

const WsnTestCont = () => {
  const { notifyTime } = useContext(WsNotifyContext);
  const testContent = useTestContentGet();
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  useEffect(() => {
    console.log("WsnTestContent - get info about notify time update");
    invalidateQueriesByKeys([QUERY_KEYS.TEST_CONTENT_GET]);
  }, [notifyTime, invalidateQueriesByKeys]);

  return (
    <div>
      <div>test content:</div>
      <div>{testContent}</div>
    </div>
  );
};

export default WsnTestCont;
