import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { QUERY_KEYS } from "constant/react_query";
import HtmlTextForm from "components/common/html_text/HtmlTextForm/HtmlTextForm";
import { HtmlTextResult } from "common/html_text/dto/HtmlTextResult";
import HtmlTextSingleNav from "components/common/html_text/HtmlTextSingleNav";
import { useHtmlTextUpdate } from "hooks/noticrud/html_text/useHtmlTextUpdate";
import { htmlTextFormDefaultValuesGet } from "common/html_text/html_text_form_common";

type HtmlTextEditProps = {
  htmlText: HtmlTextResult;
};

const HtmlTextEdit = ({ htmlText }: HtmlTextEditProps) => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: htmlTextFormDefaultValuesGet(htmlText),
  });
  const { htmlTextUpdateMutate } = useHtmlTextUpdate({
    invalidateKeysArray: [
      [QUERY_KEYS.NOTICRUD_HTML_TEXT_SEARCH],
      [QUERY_KEYS.NOTICRUD_HTML_TEXT_GET],
    ],
  });

  const onSubmit = async (data: any) => {
    console.log(data);

    const { slug, content } = data;
    htmlTextUpdateMutate({
      id: htmlText.id,
      updatePayload: {
        slug,
        content,
      },
    });
    toast.success("Html Text updated");
  };

  return (
    <DivHtmlText>
      <HtmlTextForm
        control={control}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        reset={reset}
        defaultValues={htmlTextFormDefaultValuesGet(htmlText)}
      />
      <DivNav>
        <HtmlTextSingleNav htmlText={htmlText} hideEditNav={true} />
      </DivNav>
    </DivHtmlText>
  );
};

const DivHtmlText = styled.div`
  display: flex;
  flex-direction: column;
`;

const DivNav = styled.div`
  margin-top: 1rem;
`;

export default HtmlTextEdit;
