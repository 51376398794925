export function obDefaultValGet(ob, key, defaultVal = "") {
  let val = defaultVal;
  if (ob !== null) {
    val = ob.hasOwnProperty(key) ? ob[key] : defaultVal;
  }
  return val;
}

export const envTypeDomainGet = (name) => {
  let envType = "";
  if (
    typeof process !== "undefined" &&
    typeof process.env !== "undefined" &&
    typeof process.env.NODE_ENV !== "undefined" &&
    process.env.NODE_ENV === "test"
  ) {
    envType = "test";
  } else {
    const loc = window.location.href + "";
    if (loc.includes("localhost")) {
      envType = "dev";
    } else if (loc.includes("t." + name)) {
      envType = "local_address";
    } else if (loc.includes(name)) {
      envType = "production";
    }
  }
  return envType;
};

export const envTypeGet = () => {
  return envTypeDomainGet("noticrud");
};

export const envIsProduction = () => {
  const envType = envTypeGet();
  return envType === "production";
};
