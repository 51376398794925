import { Button } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import InputField from "components/UI/InputField";
import toast from "react-hot-toast";
import { useAuthCurrent } from "hooks/auth/auth_current";
import { QUERY_KEYS } from "constant/react_query";
import { useNavigate } from "react-router-dom";
import { useAuthLogin } from "hooks/auth/useAuthLogin";

const LoginForm = () => {
  const { handleSubmit, control } = useForm({});
  const authCurrent = useAuthCurrent();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("authCurrent", authCurrent);
    if (authCurrent && authCurrent.uId !== "") {
      navigate("/");
    }
  }, [authCurrent, navigate]);

  const { loginMutate } = useAuthLogin({
    invalidateKeys: [QUERY_KEYS.AUTH_CURRENT_GET],
  });

  const onSubmit = async (data: any) => {
    loginMutate(data, {
      onSettled(data, error) {
        if (data) {
          toast.success("Login succeed");
        } else {
          if (error) {
            console.error("Login error", error);
          }
          toast.error("Login failure");
        }
      },
    });
  };

  return (
    <Form>
      <InputField
        name="un"
        control={control}
        label="Korisničko ime"
        defaultValue={""}
      />
      <InputField
        name="pw"
        control={control}
        label="Lozinka"
        defaultValue={""}
        type="password"
      />
      <Button type="submit" onClick={handleSubmit(onSubmit)}>
        OK
      </Button>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;

  &&& div {
    margin-bottom: 1rem;
  }
`;

export default LoginForm;
