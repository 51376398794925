import { useQuery } from "@tanstack/react-query";
import { htmlTextGet } from "common/api/noticrud/noticrud_html_text";
import { QUERY_KEYS } from "constant/react_query";

export const useHtmlTextGet = (id: string) => {
  const { data: htmlText } = useQuery(
    [QUERY_KEYS.NOTICRUD_HTML_TEXT_GET, id],
    async () => {
      const result = await htmlTextGet(id);
      return result;
    },
    { staleTime: Infinity }
  );
  const result =
    typeof htmlText !== "undefined" && htmlText !== "" ? htmlText : null;
  return result;
};
