import { Control, Controller, FieldValues } from "react-hook-form";
import { TextField } from "@mui/material";
import { ErrorText } from "./common.style";
import styled from "styled-components";

type Props = {
  name: string;
  control: Control<FieldValues, any>;
  label: string;
  defaultValue?: string;
  type?: string;
  onEnterHandle?: Function;
};

const InputField = ({ name, control, label, defaultValue, type }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, formState: { errors } }) => {
        const errorMessage = errors[name]?.message ?? null;
        return (
          <>
            {errorMessage && typeof errorMessage === "string" && (
              <ErrorText>{errorMessage}</ErrorText>
            )}
            <TextInputField
              onChange={onChange}
              // onKeyDown={(event: any) => {
              //   if (event.keyCode === 13) {
              //     console.log("aa 2", event.keyCode);
              //   }
              // }}
              label={label}
              defaultValue={defaultValue}
              error={typeof errors[name]?.message !== "undefined"}
              type={type}
            />
          </>
        );
      }}
    />
  );
};

const TextInputField = styled(TextField)`
  &&& .MuiFormLabel-root {
    color: currentColor;
  }

  &&& .MuiInputBase-root {
    border: ${(props) => (props?.error ? "red" : "auto")};
  }
`;

export default InputField;
