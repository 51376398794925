import { axiosNoticrud } from "../axios/noticrud_axios";

export type HtmlTextSearchParams = {
  skip?: number;
  limit?: number;
};

export type HtmlTextAddParams = {
  slug: string;
  content: string;
};

export const htmlTextAdd = async ({
  slug,
  content,
}: HtmlTextAddParams): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosNoticrud.post("html-text", { slug, content });
  } catch (error) {}
  if (response && response.status === 201) {
    result = response.data;
  }

  return result;
};

const htmlTextResponseFormat = (htmlText: any): any => {
  const result = { ...htmlText };

  return result;
};

export const htmlTextGet = async (slug: string): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosNoticrud.get(`html-text/${slug}`);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const htmlTextSearch = async (
  searchParams?: HtmlTextSearchParams
): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosNoticrud.post(`html-text/search`, searchParams ?? {});
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
    result.items = result.items.map((item: any) =>
      htmlTextResponseFormat(item)
    );
  }

  return result;
};

export const htmlTextUpdate = async (
  id: string,
  updatePayload: any
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosNoticrud.put(`html-text/${id}`, updatePayload);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const htmlTextDelete = async (id: string): Promise<boolean> => {
  let result: boolean = false;
  let response: any;
  try {
    response = await axiosNoticrud.delete(`html-text/${id}`);
    result = true;
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};
