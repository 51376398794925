import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import HtmlTextSingleNav from "components/common/html_text/HtmlTextSingleNav/HtmlTextSingleNav";
import { HtmlTextResult } from "common/html_text/dto/HtmlTextResult";
import { WsNotifyContext } from "common/ws/WsNotify";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";

type HtmlTextReadProps = {
  htmlText: HtmlTextResult;
};

const HtmlTextRead = ({ htmlText }: HtmlTextReadProps) => {
  const wsNotifyContextValue = useContext(WsNotifyContext);
  const invalidateQuery = useInvalidateQueryByKeys();
  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([
      QUERY_KEYS.NOTICRUD_HTML_TEXT_GET,
    ]);
  }, [invalidateQuery, wsNotifyContextValue.notifyTime]);

  return (
    <div>
      <div>{htmlText.slug}</div>
      <div>{htmlText.content}</div>
      <DivNav>
        <HtmlTextSingleNav htmlText={htmlText} hideReadNav={true} />
      </DivNav>
    </div>
  );
};

const DivNav = styled.div`
  margin-top: 1.5rem;
`;

export default HtmlTextRead;
