import { useMutation } from "@tanstack/react-query";
import {
  HtmlTextAddParams,
  htmlTextAdd,
} from "common/api/noticrud/noticrud_html_text";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseHtmlTextAddParams = {
  invalidateKeys?: string[];
  successHandle?: Function;
};

export const useHtmlTextAdd = (hookParams?: UseHtmlTextAddParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: htmlTextAddMutate } = useMutation({
    mutationFn: async (htmlTextAddParams: HtmlTextAddParams) =>
      await htmlTextAdd(htmlTextAddParams),
    onSuccess: (response) => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }

      if (hookParams?.successHandle) {
        hookParams.successHandle(response);
      }
    },
  });

  return { htmlTextAddMutate };
};
