type FallbackProps = {
  error: any;
  resetErrorBoundary: any;
};

export default function Fallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div>
      <p>Greska:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Pokusaj ponovo</button>
    </div>
  );
}
