import { envIsProduction } from "common/common";

export function wsUrlGet(): string {
  let url = "";
  if (!envIsProduction()) {
    url = "ws://localhost:3003";
  } else {
    url = "wss://noticrud.tehtest.emlearn.info/ws";
  }
  return url;
}
