import { useMutation } from "@tanstack/react-query";
import { htmlTextUpdate } from "common/api/noticrud/noticrud_html_text";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseHtmlTextUpdateParams = {
  invalidateKeys?: string[];
  invalidateKeysArray?: string[][];
};

export const useHtmlTextUpdate = (hookParams?: UseHtmlTextUpdateParams) => {
  const { invalidateQueriesByKeys, invalidateQueriesArraysByKeys } =
    useInvalidateQueryByKeys();
  const { mutate: htmlTextUpdateMutate } = useMutation({
    mutationFn: async (params: any) =>
      await htmlTextUpdate(params.id, params.updatePayload),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      } else if (hookParams?.invalidateKeysArray) {
        invalidateQueriesArraysByKeys(hookParams.invalidateKeysArray);
      }
    },
  });

  return { htmlTextUpdateMutate };
};
