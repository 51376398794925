const WS_MESSAGE_TYPES = {
  SEND: {
    JOIN_CHANNEL: "joinChannel",
  },
  RECEIVE: {
    NOTIFY_TIME: "notifyTime",
  },
};

const NOTIFY_TYPES = {
  FOR_NOTIFY: "forNotify",
  NOTIFIER: "notifier",
};

module.exports = {
  WS_MESSAGE_TYPES,
  NOTIFY_TYPES,
};
