import React from "react";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

export default function LoginView() {
  return (
    <Div>
      <Toaster />
      <Outlet />
    </Div>
  );
}

const Div = styled.div`
  display: flex;
  margin-top: 4rem;
  flex-direction: column;
  color: #764141;
`;
