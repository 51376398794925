import { AxiosInstance } from "axios";
import { apiTypeUrlBaseGet } from "../api_common";
import { API_APP_NAMES, API_TYPES } from "../api_constants";
import { authorizationObDefault, axiosInstanceCreate } from "./axios_common";
import { axiosInstance401Handle } from "./axios_intercepter_common";

const noticrudUrlBase: string = apiTypeUrlBaseGet(API_TYPES.NOTICRUD);

const noticrudAuth = authorizationObDefault();

const API_NOTICRUD_RETRY_LIMIT: number = 3;

export const axiosNoticrud: AxiosInstance =
  axiosInstanceCreate(noticrudUrlBase);

axiosNoticrud.interceptors.response.use(null, async (err) => {
  if (typeof err.config.retryLimit === "undefined") {
    err.config.retryLimit = API_NOTICRUD_RETRY_LIMIT;
  }
  if (err.config.retryLimit === 0) {
    throw err;
  }
  err.config.retryLimit--;
  if (err.response.status === 401) {
    try {
      await axiosInstance401Handle(
        axiosNoticrud,
        API_APP_NAMES.NOTICRUD,
        noticrudAuth
      );
    } catch (err) {
      throw err;
    }
  }
  const response = await axiosNoticrud(err.config);
  return response;
});
