import React, { useContext } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useHtmlTextAdd } from "hooks/noticrud/html_text/useHtmlTextAdd";
import { QUERY_KEYS } from "constant/react_query";
import HtmlTextForm from "components/common/html_text/HtmlTextForm/HtmlTextForm";
import { useNavigate } from "react-router-dom";
import { htmlTextFormDefaultValuesGet } from "common/html_text/html_text_form_common";

const HtmlTextNew = () => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: htmlTextFormDefaultValuesGet(),
  });
  const navigate = useNavigate();
  const { htmlTextAddMutate } = useHtmlTextAdd({
    invalidateKeys: [QUERY_KEYS.NOTICRUD_HTML_TEXT_SEARCH],
    successHandle: (id: any) => {
      console.log("add success", id);
      navigate(`/html-text/${id}`);
    },
  });

  const onSubmit = async (data: any) => {
    const { slug, content } = data;

    htmlTextAddMutate({
      slug,
      content,
    });
    toast.success("New html text added");
  };

  return (
    <>
      <div>Html Text Add</div>
      <DivHtmlText>
        <HtmlTextForm
          control={control}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          reset={reset}
        />
      </DivHtmlText>
    </>
  );
};

const DivHtmlText = styled.div`
  display: flex;
  flex-direction: column;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // column-gap: 2rem;
  // row-gap: 2rem;
  // margin-top: 2rem;
`;

export default HtmlTextNew;
