import { NOTIFY_TYPES, WS_MESSAGE_TYPES } from "constant/web_socket_notify";
import { wsUrlGet } from "./ws_common";

let wsNotify: WebSocket | null = null;
let tryCount = 0;
let timeoutCall: any = null;
const wsUrl = wsUrlGet();

function wsConnectRetry(
  messageReceiveHandle: Function,
  messageHandleAddParams: any
) {
  timeoutCall = setTimeout(
    () => connectTry(messageReceiveHandle, messageHandleAddParams),
    200
  );
}

function connectTry(
  messageReceiveHandle: Function,
  messageHandleAddParams: any,
  initialConnect: boolean = false
) {
  wsNotify = new WebSocket(wsUrl);
  if (tryCount >= 100) {
    clearInterval(timeoutCall);
  }

  wsNotify.addEventListener("message", function (event) {
    console.log(`message`);
    messageReceiveHandle(event.data, messageHandleAddParams);
  });

  wsNotify.addEventListener("open", function (event) {
    console.log(`Connected`);
    tryCount = 0;
    if (wsNotify) {
      wsNotify.addEventListener("close", function (ev) {
        console.log("close", ev);
        messageHandleAddParams.setConnectionInfo({
          ok: false,
          message: "WS not disconnected",
        });
        wsConnectRetry(messageReceiveHandle, messageHandleAddParams);
      });
    }

    messageHandleAddParams.setConnectionInfo({ ok: true, message: "" });
    wsNotifyNotifierJoinChannel();
  });

  if (initialConnect) {
    wsNotify.addEventListener("error", (ev) => {
      console.log("error ev", ev);
      messageHandleAddParams.setConnectionInfo({
        ok: false,
        message: "WS connection error",
      });
      wsConnectRetry(messageReceiveHandle, messageHandleAddParams);
    });
  } else {
    wsNotify.onerror = (ev) => {
      tryCount++;
      console.log("ws status tryCount: ", tryCount);
      messageHandleAddParams.setConnectionInfo({
        ok: false,
        message: "WS connection error",
      });
      setTimeout(
        () => connectTry(messageReceiveHandle, messageHandleAddParams),
        100
      );
    };
  }
}

export function wsInit(
  messageReceiveHandle: Function,
  messageHandleAddParams: any
) {
  console.log("wsInit start");

  if (wsNotify === null) {
    connectTry(messageReceiveHandle, messageHandleAddParams, true);
  }
}

function wsNotifyNotifierJoinChannel() {
  if (wsNotify) {
    const data = {
      msgType: WS_MESSAGE_TYPES.SEND.JOIN_CHANNEL,
      payload: NOTIFY_TYPES.FOR_NOTIFY,
    };
    wsNotify.send(JSON.stringify(data));
  }
}
