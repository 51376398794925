import { useQuery } from "@tanstack/react-query";
import {
  HtmlTextSearchParams,
  htmlTextSearch,
} from "common/api/noticrud/noticrud_html_text";
import { QUERY_KEYS } from "constant/react_query";

export const useHtmlTextSearch = (searchParams?: HtmlTextSearchParams) => {
  const { data: htmlText } = useQuery(
    [QUERY_KEYS.NOTICRUD_HTML_TEXT_SEARCH, searchParams],
    async () => {
      const result = await htmlTextSearch(searchParams);
      return result;
    },
    { staleTime: Infinity }
  );
  const result =
    typeof htmlText !== "undefined" && htmlText !== "" ? htmlText : null;
  return result;
};
