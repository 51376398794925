import { useEffect, useState } from "react";
import { axiosNoticrud } from "common/api/axios/noticrud_axios";

const NoticrudStart = () => {
  const [msgAuthRequiredNoticrud, setMsgAuthRequiredNoticrud] = useState<any>(
    "noticrud not accessed"
  );

  useEffect(() => {
    axiosNoticrud
      .get("auth/authrequired")
      .then((res) => {
        console.log("noticrud res authrequired", res);
        setMsgAuthRequiredNoticrud(res.statusText);
      })
      .catch((err) => {
        console.log("noticrud res authrequired err", err.response.status);
      });
  }, []);

  return (
    <div>
      <div>START</div>
      <div>Noticrud: {msgAuthRequiredNoticrud}</div>
    </div>
  );
};

export default NoticrudStart;
